<template>
  <div class="create-new-deal">
    <div v-loading="is_panding" class="create-new-deal-form slide-in-top">
        <div class="create-new-deal-form-header">
            <h1 v-if="!edit">יצירת מבצע</h1>
            <h1 v-if="edit">עריכת מבצע קיים</h1>
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
        </div>
        <div class="create-new-deal-form-content">
            <div class="create-new-deal-form-content-field">
                <h2>קוד מבצע</h2>
                <el-input v-if="!edit" v-model="values.deal_uid" placeholder="קוד מבצע" />
                <el-input v-if="edit"  disabled :placeholder="values.deal_uid"/>
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>מחלקה</h2>
                <el-select style="width:100%;" v-model="values.department" class="m-2" placeholder="מחלקה" size="large">
                    <el-option
                    v-for="department in deps"
                    :key="department.uid"
                    :value="department.name"
                    />
                </el-select>
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>שם המבצע בעברית</h2>
                <el-input v-model="values.he_name" placeholder="שם בעברית" />
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>שם המבצע בלועזית</h2>
                <el-input v-model="values.ru_name" placeholder="שם בלועזית" />
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>כמות</h2>
                <el-input type="number" style="width:100%;" v-model="values.qnt" placeholder="כמות" />
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>מחיר</h2>
                <el-input type="number" style="width:100%;" v-model="values.price" placeholder="מחיר" :min="1" :step="0.01" :max="100"/>
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>מחיר מקורי</h2>
                <el-input type="number" style="width:100%;" v-model="values.origin_price" placeholder="מחיר מקורי" :min="1" :step="0.01" :max="100"/>
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>מתאריך</h2>
                <el-input type="date" v-model="values.start_date" placeholder="מתאריך"/>
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>עד תאריך</h2>
                <el-input type="date" v-model="values.end_date" placeholder="עד תאריך"/>
            </div>
            <div class="create-new-deal-form-content-field">
                <h2>יחידת מידת המוצרים השייכים למבצע</h2>
                <el-select style="width:100%;" v-model="values.size" class="m-2" placeholder="בחר יחידת מידה" size="large">
                    <el-option
                    v-for="size in size_options"
                    :key="size"
                    :value="size"
                    />
                </el-select>
            </div>
            <div v-if="values.size=='יח'" class="create-new-deal-form-content-field">
                <h2>נפח המוצר (בגרמים)</h2>
                <el-input type="number" style="width:100%;" v-model="values.volume" placeholder="יחידות" />
            </div>
            <div class="create-new-deal-form-content-field">
                <el-button @click="show_choose_items=true" type="primary">בחירת מוצרים למבצע</el-button>
            </div>
            <div v-if="values.selected_signs.length>0" class="selected-signs-list">
                <template v-for="{barcode,he_name} in values.selected_signs" :key="barcode">
                    <div class="barcode">
                        <p>{{he_name}}</p>
                        <p>{{barcode}}</p>
                    </div>
                </template>
            </div>
        </div>
        <div class="create-new-deal-form-footer">
            <el-button v-if="!edit" @click="handle_submit" style="width:80%;" type="success">צור חדש</el-button>
            <el-button v-if="edit" @click="handle_submit_edit" style="width:80%;" type="warning">עדכון</el-button>
        </div>
    </div>
    <div v-if="show_choose_items" class="choose-items slide-in-top">
        <ShilutDb
            @back="show_choose_items=false" 
            @reload_signs="handle_reload_signs" 
            :selected_items_before="values.selected_signs" 
        />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'
import {check_if_deal_exist,store_new_deal_in_db} from './Scripts/script'
import ShilutDb from '../OptionMenu/ShilutDb.vue'
import { watch } from '@vue/runtime-core'
export default {
    props:['departments','edit'],
    emits:['close','submit','submit_edit'],
    components:{ShilutDb},
    setup(props,{emit}){
        const show_choose_items = ref(false)
        const deps = ref([])
        const is_panding = ref(false)
        const err_msg = ref('')
        
        const size_options = ref([
            'ק\"ג',
            'יח'
        ])
        const values = ref({
            deal_uid:'',
            department:'',
            he_name:'',
            ru_name:'',
            qnt:"1",
            price:"1",
            origin_price:"1",
            start_date:'',
            end_date:'',
            size:'',
            volume:"1",
            selected_signs:[]
        })



        const handle_reload_signs = (signs) => {
            values.value.selected_signs = signs
            show_choose_items.value = false
        }

        const validation = async() => {
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'deal_uid':
                        if(!value){
                            err_msg.value = 'עליך להזין מס מבצע!'
                            return false
                        }
                        else{
                            const exist = await check_if_deal_exist(value)
                            if(exist && !props.edit){
                                err_msg.value = `המבצע ${value} כבר קיים!`
                                return false
                            }
                            if(containsSpecialChars(value)){
                                err_msg.value = 'קוד המבצע לא יכול להכיל תווים מיוחדים!'
                                return false
                            }
                        }
                        break;
                    case 'department':
                        if(!value){
                            err_msg.value = 'עליך לבחור מחלקה!'
                            return false
                        }
                        break;
                    case 'he_name':
                        if(!value){
                            err_msg.value = 'עליך להזין שם בעברית!'
                            return false
                        }
                        break;
                    case 'ru_name':
                        if(!value){
                            err_msg.value = 'עליך להזין שם ברוסית!'
                            return false
                        }
                        break;
                    case 'ru_name':
                        if(!value){
                            err_msg.value = 'עליך להזין שם ברוסית!'
                            return false
                        }
                        break;
                    case 'start_date':
                        if(!value){
                            err_msg.value = 'עליך להזין תאריך התחלה!'
                            return false
                        }
                        break;
                    case 'end_date':
                        if(!value){
                            err_msg.value = 'עליך להזין תאריך סיום!'
                            return false
                        }
                        break;
                    case 'selected_signs':
                        if(value.length==0){
                            err_msg.value = 'עליך לבחור לפחות מוצר אחד שקשור למבצע!'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        
        const handle_submit = async() => {
            if(!await validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_panding.value = true
                await store_new_deal_in_db(values.value)
                is_panding.value = false
                alert('success','הצלחה',
                `המבצע ${values.value.deal_uid} נקלט בהצלחה`)
                .then(()=>{
                    emit('submit',values.value)
                })
            }
        }

        const handle_submit_edit = async() => {
            if(!await validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_panding.value = true
                await store_new_deal_in_db(values.value)
                is_panding.value = false
                alert('success','הצלחה',
                `המבצע ${values.value.deal_uid} עודכן בהצלחה`)
                .then(()=>{
                    emit('submit_edit',values.value)
                })
            }
        }

        function containsSpecialChars(str) {
            const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            return specialChars.test(str);
        }

        const init = () => {
            if(props.departments){
                deps.value = props.departments.filter(dep=>dep.name!='הכל')
            }
            if(props.edit){
                values.value = props.edit
            }
        }

        init()
        
        return{
            handle_submit,
            handle_submit_edit,
            handle_reload_signs,
            values,
            err_msg,
            is_panding,
            deps,
            show_choose_items,
            size_options,
        }
    }
}
</script>

<style scoped>
    .create-new-deal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        z-index: 1000;
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .create-new-deal-form{
        width: 100%;
        max-width: 500px;
        height: auto;
        max-height: 600px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background: var(--main);
    }
    .create-new-deal-form-header{
        position: relative;
        width: 100%;
        height: 50px;
        border-radius: 10px 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
    .create-new-deal-form-content{
        width: 100%;
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
        color: #fff;
        padding: 10px 0;
    }
    .create-new-deal-form-content-field{
        width: 100%;
        height: fit-content;
        padding: 0 5px;
        margin-top: 5px;
    }
    .create-new-deal-form-footer{
        width: 100%;
        height: 50px;
        background: var(--main);
        border-radius: 0 0 10px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }


    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

    .choose-items{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1001;
        background: rgba(0, 0, 0, 0.99);
    }
    .selected-signs-list{
        width: 100%;
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
    }
    .selected-signs-list .barcode{
        padding: 5px;
        margin: 5px;
        color: #fff;
        border-radius: 10px;
        background: var(--purple);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>